import React, { useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { format } from 'date-fns';
import { enUS, nl } from 'date-fns/locale';
import { useSelector } from 'react-redux';

import { Avatar } from 'Components/Shared/UI';
import ImageSliderBadge from 'Components/Plugins/Sliders/ImageSliderBadge';

import { GLOBAL_CONSTANTS } from 'utils/constants';
import generateImgPath from 'utils/generateImgPath';
import { convertCapitalize } from 'utils/handlers';

import { baseSelector } from 'store/reselect';

import API from 'services/api';

import useStyles from './styles';

const EventTile = ({
  data: {
    city,
    title,
    photos,
    labels,
    title_slug: slug,
    start_date: date,
    category_id: categoryId,
    reference_id: referenceId,
  },
}) => {
  const t = useTranslations();
  const styles = useStyles();
  const { lang } = useSelector(baseSelector());

  const currentLocale = lang === 'nl' ? nl : enUS;

  const [promotions, setPromotions] = useState({});

  const isSliderImage = photos?.constructor.name === 'Array';

  const eventType = labels[0];

  const { value: categoryName } = GLOBAL_CONSTANTS.categories.find(
    el => el.id === categoryId,
  );

  // TODO::: Temporary solution for capitalizing week day, as after using locale in dutch they were in lower case

  const formattedDate = format(new Date(date), 'EEEE dd MMM. HH:mm', {
    locale: currentLocale,
  });

  const getPromotion = useMemo(
    () =>
      promotions.silver || promotions.gold ? (
        <div className="promotion-icon">
          {promotions.gold ? (
            <img src="/images/icons/gold_icon.svg" alt="gold" />
          ) : promotions.silver ? (
            <img src="/images/icons/silver_icon.svg" alt="silver" />
          ) : (
            ''
          )}
        </div>
      ) : null,
    [promotions],
  );

  useEffect(() => {
    (async () => {
      try {
        const { data: promotions } = await API.fetchAdPromotions(referenceId);
        setPromotions(promotions);
      } catch (er) {
        console.error(er);
      }
    })();
  }, [referenceId]);

  return (
    <Link href={slug}>
      <a>
        <div className={styles['event--block']}>
          <div className="image-wrapper">
            {getPromotion}
            {isSliderImage ? (
              <ImageSliderBadge src={generateImgPath(photos, 'thumb_248')} />
            ) : (
              <Avatar
                className="cover-photo"
                avatarSrc={generateImgPath(photos, 'thumb_248')}
                defaultSrc={'/images/icons/upload.png'}
                height={196}
                width={'100%'}
                name={''}
                radius={0}
              />
            )}
          </div>
          <div className={styles['details--block']}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '78px',
                justifyContent: 'space-between',
              }}
            >
              <div className="time-content">
                {date && (
                  <p style={{ textTransform: 'capitalize' }}>{formattedDate}</p>
                )}
              </div>
              <div>
                {promotions.tagline && (
                  <span
                    style={{
                      display: 'inline-block',
                      maxWidth: 'max-content',
                      margin: 0,
                      fontWeight: 'bold',
                    }}
                    className={`sticker__tile sticker__tile--${
                      promotions.gold || 'silver'
                    }`}
                  >
                    {promotions.tagline}
                  </span>
                )}
              </div>
              <div className="title-info" style={{ marginTop: 2 }}>
                <h4 className="title-content">{title}</h4>
              </div>
            </div>
            <div className={styles['details--block-section__bt']}>
              <p>
                <span className="icon-Advertenties"></span>
                {t(categoryName)}
              </p>
              <p>
                <span className="icon-Tag"></span>
                {t(eventType)}
              </p>
              <p>
                <span className="icon-Locatie"></span>
                {city}
              </p>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default React.memo(EventTile);
